/**
 * PrizeChecker component
 * The PrizeChecker component allows you to check a code against JSON API.
 *
 * To edit the component's style, do the following:
 * 1. Add a control to the `addPropertyControls` function below.
 * 2. Add a typescript type to the `UitslagProps` interface below (this is optional, but recommended for Typescript users).
 * 3. Map the control to a CSS property in the `Styles` section below. For example, if a style "borderRadius" is added for the button
 *   control, add a `borderRadius: style.borderRadius` to the `mapButtonStyle` function.
 */

import React, { useState, useRef, CSSProperties } from "react"
import { ControlType, addPropertyControls } from "framer"

/// ----------------------------
/// Section: Framer controls
/// Define the properties that can be edited from the Framer UI.
/// ----------------------------

interface HeaderStyle {
    text: string
    color: string
    fontFamily: string
    fontWeight: string
    fontSize: number
}

interface ButtonStyle {
    text: string
    color: string
    bg: string
    fontFamily: string
    fontWeight: string
    fontSize: number
    radius: number
    padding: { x: number; y: number }
    uppercase: boolean
}

/**
 * These controls are used to set the properties of text layers.
 */
const textStyleControl = {
    type: ControlType.Object,
    controls: {
        fontFamily: { type: ControlType.String, defaultValue: "Inter" },
        fontWeight: {
            type: ControlType.Enum,
            defaultValue: "400",
            options: [
                "200",
                "italic 200",
                "300",
                "italic 300",
                "400",
                "italic 400",
                "500",
                "italic 500",
                "600",
                "italic 600",
                "700",
                "italic 700",
                "800",
                "italic 800",
            ],
            optionTitles: [
                "Extra Light",
                "Extra Light Italic",
                "Light",
                "Light Italic",
                "Regular",
                "Italic",
                "Medium",
                "Medium Italic",
                "Semibold",
                "Semibold Italic",
                "Bold",
                "Bold Italic",
                "Extra Bold",
                "Extra Bold Italic",
            ],
        },
        color: {
            title: "Color",
            type: ControlType.Color,
        },
        fontSize: {
            type: ControlType.Number,
            defaultValue: 16,
            unit: "px",
            step: 1,
            displayStepper: true,
        },
    },
}

/**
 * These controls are used to set the properties of the Pincode Inputs.
 */
const inputStyleControl = {
    type: ControlType.Object,
    controls: {
        bg: {
            title: "Background Color",
            type: ControlType.Color,
            defaultValue: "#F5F5F5",
        },
        color: {
            title: "Text Color",
            type: ControlType.Color,
            defaultValue: "#000000",
        },
        radius: {
            type: ControlType.Number,
            defaultValue: 5,
            displayStepper: true,
            unit: "px",
        },
    },
}

/**
 * These controls are used to set the properties of the buttons
 */
const buttonStyleControl = {
    type: ControlType.Object,
    controls: {
        fontFamily: { type: ControlType.String, defaultValue: "Inter" },
        bg: {
            title: "Background Color",
            type: ControlType.Color,
            defaultValue: "#F5F5F5",
        },
        color: {
            title: "Text Color",
            type: ControlType.Color,
            defaultValue: "#000000",
        },
        radius: {
            type: ControlType.Number,
            defaultValue: 5,
            displayStepper: true,
            unit: "px",
        },
        fontSize: {
            type: ControlType.Number,
            defaultValue: 16,
            unit: "px",
            step: 1,
            displayStepper: true,
        },
        padding: {
            type: ControlType.Object,
            controls: {
                x: {
                    type: ControlType.Number,
                    defaultValue: 16,
                    unit: "px",
                    step: 1,
                    displayStepper: true,
                },
                y: {
                    type: ControlType.Number,
                    defaultValue: 16,
                    unit: "px",
                    step: 1,
                    displayStepper: true,
                },
            },
        },
        fontWeight: {
            type: ControlType.Enum,
            defaultValue: "400",
            options: [
                "200",
                "italic 200",
                "300",
                "italic 300",
                "400",
                "italic 400",
                "500",
                "italic 500",
                "600",
                "italic 600",
                "700",
                "italic 700",
                "800",
                "italic 800",
            ],
            optionTitles: [
                "Extra Light",
                "Extra Light Italic",
                "Light",
                "Light Italic",
                "Regular",
                "Italic",
                "Medium",
                "Medium Italic",
                "Semibold",
                "Semibold Italic",
                "Bold",
                "Bold Italic",
                "Extra Bold",
                "Extra Bold Italic",
            ],
        },
        uppercase: {
            type: ControlType.Boolean,
            defaultValue: false,
            title: "Uppercase",
        },
    },
}

/**
 * These controls are added to the component in Framer so that the user can edit them in the Framer UI.
 */
addPropertyControls(Uitslag, {
    endpoint: { type: ControlType.String, title: "Endpoint" },
    structure: {
        type: ControlType.Array,
        title: "Structure",
        defaultValue: [
            "letter",
            "letter",
            "number",
            "number",
            "number",
            "number",
            "number",
        ],
        control: {
            type: ControlType.Enum,
            displaySegmentedControl: true,
            segmentedControlDirection: "horizontal",
            defaultValue: "letter",
            options: ["letter", "number", "-"],
            optionTitles: ["Letter", "Number", "-"],
        },
    },
    defaultText: { type: ControlType.String, title: "Default text" },
    successText: { type: ControlType.String, title: "Success text" },
    failText: { type: ControlType.String, title: "Fail text" },
    buttonStyle: { ...buttonStyleControl, title: "Button Styles" },
    headerStyle: { ...textStyleControl, title: "Header Styles" },
    inputStyle: { ...inputStyleControl, title: "Input Styles" },
})

/// --------------------------------------------------
/// Section: Styles
/// Map the Framer control properties to CSS properties.
/// --------------------------------------------------
const mapTextStyle = (style: HeaderStyle): CSSProperties => {
    return {
        color: style.color,
        fontFamily: style.fontFamily,
        textAlign: "center",
    }
}

const mapHeaderStyle = (style: HeaderStyle): CSSProperties => {
    return {
        color: style.color,
        fontFamily: style.fontFamily,
        fontSize: style.fontSize,
        fontWeight: style.fontWeight,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        marginBottom: 25,
    }
}

const mapButtonStyle = (style: ButtonStyle): CSSProperties => {
    return {
        backgroundColor: style.bg,
        color: style.color,
        outline: "none",
        border: 0,
        borderRadius: style.radius,
        fontFamily: style.fontFamily,
        fontSize: style.fontSize,
        fontWeight: style.fontWeight,
        padding: `${style.padding.y}px ${style.padding.x}px`,
        cursor: "pointer",
        textTransform: style.uppercase ? "uppercase" : "none",
    }
}

/// --------------------------------------------------
/// Section: Components
/// Handles the rendering of the different components
/// --------------------------------------------------

interface SuccessComponentProps {
    text: string
    headerStyle: HeaderStyle
    buttonStyle: ButtonStyle
    result: {
        draw_at: string
        title: string
        value: number
    }
    onRetry: () => void
}

/**
 * Render this if the code is valid and the API returned a result.
 */
const SuccessComponent = ({
    headerStyle,
    text,
    buttonStyle,
    result,
    onRetry,
}: SuccessComponentProps) => {
    const headerStyles = mapHeaderStyle(headerStyle)
    const buttonStyles = mapButtonStyle(buttonStyle)
    const textStyles = mapTextStyle(headerStyle)

    return (
        <div>
            <div style={{ marginBottom: 25, textAlign: "center" }}>
                <span style={headerStyles}>{text}</span>
                <span style={{ fontSize: 16, ...textStyles }}>
                    Je hebt gewonnen: <strong>{result.title}</strong>
                </span>
            </div>
            <button style={buttonStyles} onClick={() => onRetry()}>
                Probeer een ander lotnummer
            </button>
        </div>
    )
}

interface FailComponentProps {
    text: string
    headerStyle: HeaderStyle
    buttonStyle: ButtonStyle
    onRetry: () => void
}

/**
 * Render this if the code is invalid and the API returned no result.
 */
const FailComponent = ({
    headerStyle,
    text,
    buttonStyle,
    onRetry,
}: FailComponentProps) => {
    const headerStyles = mapHeaderStyle(headerStyle)
    const buttonStyles = mapButtonStyle(buttonStyle)

    return (
        <div style={{ textAlign: "center" }}>
            <div style={{ marginBottom: 25 }}>
                <span style={headerStyles}>{text}</span>
            </div>
            <button style={buttonStyles} onClick={() => onRetry()}>
                Probeer een ander lotnummer
            </button>
        </div>
    )
}

interface UitslagProps {
    structure: string[]
    endpoint: string
    defaultText: string
    successText: string
    failText: string
    inputStyle: {
        bg: string
        color: string
        radius: number
    }
    buttonStyle: ButtonStyle
    headerStyle: HeaderStyle
}

/**
 * The PrizeChecker component.
 * This component allows you to check a code against JSON API.
 */
export default function Uitslag(props: UitslagProps) {
    const structure = props.structure || [
        "letter",
        "letter",
        "number",
        "number",
        "number",
        "number",
        "number",
    ]

    /**
     * Code validator
     */
    const validateCode = (code) => {
        if (code.length !== structure.length) {
            return false
        }

        let valid = true

        code.forEach((character, index) => {
            if (structure[index] === "-" && character !== "-") {
                valid = false
            }

            if (structure[index] === "letter" && !character.match(/[A-Za-z]/)) {
                valid = false
            }

            if (structure[index] === "number" && !character.match(/[0-9]/)) {
                valid = false
            }
        })

        return valid
    }

    // TODO: Defaultcode undo?
    const defaultCode = structure.map((structure) => "")

    /**
     * The code is an array of items.
     *  On init:
     * - If it exists in localstorage, use that.
     * - Else, initialize it from the structure (list of empty strings except for hte dash).
     */
    const [code, setCode] = useState<string[]>(() => {
        const storedCheckerCode = localStorage.getItem("UitslagCode")

        if (storedCheckerCode && validateCode(storedCheckerCode.split(""))) {
            return storedCheckerCode.split("")
        }

        return structure.map((value) => (value === "-" ? value : ""))
    })

    /**
     * On init, set a regex for local validation.
     */
    const [codeRegex] = useState(() => {
        return new RegExp(
            structure
                .map((element) => (element === "letter" ? "\\w" : "\\d"))
                .join(""),
            "i"
        )
    })

    /**
     * API results.
     */
    const [result, setResult] = useState(null)
    const [noResult, setNoResult] = useState(false)
    const [loading, setLoading] = useState(false)

    /**
     * List of refs to refer to the relevant pincode input.
     */
    const inputsRef = useRef([])

    /**
     * Parse the code
     */
    const parseCode = (copiedString) => {
        const match = copiedString.match(codeRegex)
        return match ? match[0] : ""
    }

    /**
     * Handler to change code on input. Used by setLetterValue and setNumberValue.
     */
    const setValue = (value, index) => {
        setCode((prevCode) => {
            const updatedCode = [...prevCode]
            updatedCode[index] = value
            return updatedCode
        })

        if (value) {
            let nextIndex = index + 1
            while (nextIndex < inputsRef.current.length) {
                if (inputsRef.current[nextIndex]) {
                    inputsRef.current[nextIndex].focus()
                    inputsRef.current[nextIndex].select()
                    break
                } else {
                    nextIndex++
                }
            }
        }
    }

    /**
     * Wrapper around setValue for letters.
     */
    const setLetterValue = (index) => {
        return (event) => {
            let value = event.target.value
            if (!value || (value.length === 1 && value.match(/[A-Za-z]/))) {
                value = value.toUpperCase()
                setValue(value, index)
            }
            event.preventDefault()
        }
    }

    /**
     * Wrapper around setValue for numbers.
     */
    const setNumberValue = (index) => {
        return (event) => {
            let value = event.target.value
            if (!value || (value.length === 1 && value.match(/[0-9]/))) {
                setValue(value, index)
            }
            event.preventDefault()
        }
    }

    /**
     * Handler for when someone types in.
     */
    const handleKeyDown = (index) => {
        return (event) => {
            let keyCode = event.keyCode || event.charCode
            let value = event.target.value
            if (!value && keyCode === 8 && index > 0) {
                // Backspace
                while (index - 1 >= 0) {
                    if (inputsRef.current[index - 1]) {
                        inputsRef.current[index - 1].focus()
                        inputsRef.current[index - 1].select()
                        break
                    } else {
                        index--
                    }
                }
            }
        }
    }

    /**
     * Handler for when someone pastes a full code.
     */
    const handlePaste = (index) => {
        return (event) => {
            event.clipboardData.items[0].getAsString((value) => {
                let code = value.toUpperCase()
                code = parseCode(code)
                if (code.length === 1) {
                    if (structure[index] === "letter") {
                        setLetterValue(index)({
                            target: {
                                value: code,
                            },
                        })
                    } else if (structure[index] === "number") {
                        setNumberValue(index)({
                            target: {
                                value: code,
                            },
                        })
                    }
                } else if (validateCode(code.split(""))) {
                    setCode(code.split(""))
                    inputsRef.current[code.length - 1].focus()
                }
            })
            event.preventDefault()
        }
    }

    /**
     * Validate local code against API connection.
     */
    const fetchPrize = () => {
        let codeValue = code.join("")

        if (codeValue.length !== structure.length) {
            return
        }

        localStorage.setItem("UitslagCode", codeValue)

        setLoading(true)
        setResult(null)

        // Make URL object of { params: codeValue }
        const params = new URLSearchParams({ params: codeValue }).toString()

        const url = `${props.endpoint}?${params}`

        return fetch(url, {
            method: "GET",
        }).then(async (response) => {
            const body = await response.json()

            if (codeValue in body) {
                setResult(body[codeValue])
                setNoResult(false)
            } else {
                setResult(null)
                setNoResult(true)
            }
            setLoading(false)
        })
    }

    /**
     * Undoes everything
     */
    const resetResult = () => {
        localStorage.removeItem("UitslagCode")
        const codeValue = structure.map((value) => (value === "-" ? value : ""))
        setCode(codeValue)
        setResult(null)
        setNoResult(false)
        setLoading(false)
        document.body.classList.remove("prize-checker-positive")
        document.body.classList.remove("prize-checker-negative")
    }

    const inputStyle: CSSProperties = {
        color: props.inputStyle.color,
        borderRadius: `${props.inputStyle.radius}px`,
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: 60,
        height: 60,
        background: props.inputStyle.bg,
        boxShadow: "none",
        border: 0,
        margin: 5,
        fontSize: 20,
    }

    const headerStyles = mapHeaderStyle(props.headerStyle)
    const buttonStyles = mapButtonStyle(props.buttonStyle)

    return (
        <div
            className="prize-checker"
            style={{ display: "flex", justifyContent: "center" }}
        >
            <style>
                {`
            /* Works for Chrome, Safari, Edge, Opera */
            .pincode-item::-webkit-outer-spin-button,
            .pincode-item::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            /* Works for Firefox */
            .pincode-item[type="number"] {
              -moz-appearance: textfield;
            }`}
            </style>
            {loading && <div className="loader"></div>}
            {!loading && !result && !noResult && (
                <div>
                    <span style={headerStyles}>{props.defaultText}</span>
                    <div
                        className="inputs"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                        }}
                    >
                        {structure.map((type, i) => (
                            <span key={i}>
                                {type === "letter" && (
                                    <input
                                        className="pincode-item"
                                        style={inputStyle}
                                        type="text"
                                        value={code[i]}
                                        onChange={setLetterValue(i)}
                                        onKeyDown={handleKeyDown(i)}
                                        onPaste={handlePaste(i)}
                                        maxLength={1}
                                        ref={(el) =>
                                            (inputsRef.current[i] = el)
                                        }
                                        placeholder={defaultCode[i]}
                                    />
                                )}
                                {type === "number" && (
                                    <input
                                        className="pincode-item"
                                        style={inputStyle}
                                        type="number"
                                        value={code[i]}
                                        onChange={setNumberValue(i)}
                                        onKeyDown={handleKeyDown(i)}
                                        onPaste={handlePaste(i)}
                                        maxLength={1}
                                        ref={(el) =>
                                            (inputsRef.current[i] = el)
                                        }
                                        placeholder={defaultCode[i]}
                                    />
                                )}
                                {type === "-" && (
                                    <span className="sep"> &ndash; </span>
                                )}
                            </span>
                        ))}
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 25,
                        }}
                    >
                        <button
                            style={buttonStyles}
                            onClick={() => fetchPrize()}
                        >
                            Check
                        </button>
                    </div>
                </div>
            )}
            {!loading && result && (
                <SuccessComponent
                    text={props.successText}
                    result={result}
                    headerStyle={props.headerStyle}
                    buttonStyle={props.buttonStyle}
                    onRetry={resetResult}
                />
            )}
            {!loading && noResult && (
                <FailComponent
                    text={props.failText}
                    headerStyle={props.headerStyle}
                    buttonStyle={props.buttonStyle}
                    onRetry={resetResult}
                />
            )}
        </div>
    )
}
